import { useEffect } from "react";
import Aos from "aos";
import ImgLogo from "./assets/img/img-logo.png";
import ImgBg from "./assets/img/img-bg.png";
import ImgIntro from "./assets/img/img-intro.png";
import ImgTokenomics from "./assets/img/img-tokenomics.png";
import ImgBottom from "./assets/img/img-bottom.png";
import "./App.scss";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);

  return (
    <div className="App">
      <div className="img-bg">
        <img src={ImgBg} alt="" />
      </div>
      <div className="wrapper">
        <div className="container">
          <header className="page-header">
            <nav className="nav-menu">
              <ul>
                <li>
                  <a href="">home</a>
                </li>
                <li>
                  <a href="">chart</a>
                </li>
                <li>
                  <a
                    href="https://dexscreener.com/solana/bgkqrnyftdzprty8s7rwtc8hcf1nyss2tks4jbk9wzex"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    buy
                  </a>
                </li>
              </ul>
            </nav>
          </header>
          <main className="page-main">
            <section data-aos="fade-up" className="section-intro">
              <div className="content-left">
                <div className="row-center">
                  <div className="img-left">
                    <img src={ImgLogo} alt="" />
                  </div>
                  <h1 className="title">VINNY</h1>
                </div>
                <p className="des">
                  Meet Vinny, the four-legged companion to Josh Fried, Business
                  Development at Solana. Vinny, with boundless energy and
                  unwavering loyalty, brings an extra dash of enthusiasm to the
                  dynamic world of blockchain. As the tail-wagging co-pilot to
                  one of Solana's key players, Vinny embodies the spirit of
                  collaboration and joy that defines the culture at Solana.
                </p>
                <div className="contact-info">
                  <p>Contract Address</p>
                  <div className="field">
                    ExVV64Lkb49Bh4PK7QMijLQAuFPSvAt6eToLECn6oBc9
                  </div>
                </div>
              </div>
              <div className="img-right">
                <img src={ImgIntro} alt="" />
              </div>
            </section>
            <section data-aos="fade-up" className="section-tokenomics">
              <div className="img-left">
                <img src={ImgTokenomics} alt="" />
              </div>
              <div className="content-right">
                <h3 className="title">
                  TOKENOMICS OF<span> $VINNY. </span>
                </h3>
                <div className="des">
                  $VINNY Contract has completed revoke mint, Liquidity burnt and
                  no taxes included.
                </div>
                <div className="group-field">
                  <p className="label">Total Supply</p>
                  <div className="field">100,000,000</div>
                </div>
                <div className="group-field">
                  <p className="label">Taxes</p>
                  <div className="field field-2">
                    Buy 0%
                    <span>Sell 0%</span>
                  </div>
                </div>
                <div className="group-field">
                  <p className="label">Liquidity Burnt</p>
                </div>
              </div>
            </section>
            <section data-aos="fade-up" className="section-bottom">
              <div className="img-bottom">
                <img src={ImgBottom} alt="" />
              </div>
            </section>
          </main>
        </div>
        <footer className="page-footer">
          <p className="copyright">$VINNY | All Rights Reserved | 2023</p>
          <ul>
            <li>
              <a href="">RAYDIUM</a>
            </li>
            <li>
              <a
                href="https://dexscreener.com/solana/bgkqrnyftdzprty8s7rwtc8hcf1nyss2tks4jbk9wzex"
                target="_blank"
                rel="noopener noreferrer"
              >
                DEXSCREENER
              </a>
            </li>
            <li>
              <a
                href="https://t.me/VinnySolana"
                target="_blank"
                rel="noopener noreferrer"
              >
                TELEGRAM
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/VinnySolana"
                target="_blank"
                rel="noopener noreferrer"
              >
                TWITTER
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </div>
  );
}

export default App;
